// store/store.jsx
import { Provider, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer';
import {
  setLocation,
  setMessages,
  setPanel,
  setPanelType,
  setTheme,
  removeMessageById,
  removePanel,
  setToast,
  setPayment,
  setOrderItemCount,
  setPanelSwitch,
  setSuggestions,
  setOrgSettings,
  logout,
  setWebSocket
} from './app/appActions';

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});

export function useStore() {
  const appData = useSelector((state) => state.mainAppData);
  const dispatch = useDispatch();
  return {
    messages: appData.messages,
    panels: appData.panels,
    panelType: appData.panelType,
    location: appData.location,
    orgSettings: appData.orgSettings,
    themes: appData.themes,
    toast: appData.toast,
    orderItemCount: appData.orderItemCount,
    paymentAdded: appData.paymentAdded,
    suggestions: appData.suggestions,
    webSocket: appData.webSocket,
    setMessages: (payload) => dispatch(setMessages(payload)),
    setPanel: (payload) => dispatch(setPanel(payload)),
    setPanelType: (payload) => dispatch(setPanelType(payload)),
    setLocation: (payload) => dispatch(setLocation(payload)),
    setTheme: (payload) => dispatch(setTheme(payload)),
    setOrgSettings: (payload) => dispatch(setOrgSettings(payload)),
    removeMessageById: (payload) => dispatch(removeMessageById(payload)),
    removePanel: (payload) => dispatch(removePanel(payload)),
    setToast: (payload) => dispatch(setToast(payload)),
    setPayment: (payload) => dispatch(setPayment(payload)),
    setOrderItemCount: (payload) => dispatch(setOrderItemCount(payload)),
    setPanelSwitch: (payload) => dispatch(setPanelSwitch(payload)),
    setSuggestions: (payload) => dispatch(setSuggestions(payload)),
    logout: () => dispatch(logout()),
    setWebSocket: (payload) => dispatch(setWebSocket(payload))
  };
}
// Clears the persisted store every time the page reloads
if (typeof window !== 'undefined') {
  localStorage.clear();
}

const StoreProvider = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};
export default StoreProvider;
export const persistor = persistStore(store);
