import React from 'react';
import './LoginSkeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function LoginSkeleton() {
  return (
    <SkeletonTheme baseColor='#202020' highlightColor='#444'>
      <div className='main'>
        <div>
          <div className='avatar'>
            <Skeleton circle height={120} width={120} />
          </div>
          <div className='heading'>
            <Skeleton containerClassName='heading-skeleton' />
          </div>
          <div style={{ marginTop: '5px' }} className='heading'>
            <Skeleton height={30} containerClassName='heading-skeleton' />
          </div>
          <div style={{ marginTop: '91px' }} className='login-input'>
            <Skeleton borderRadius={32} height={30} containerClassName='login-input-skeleton' />
          </div>
          <div style={{ marginTop: '110px' }} className='login-input'>
            <Skeleton borderRadius={32} height={30} containerClassName='login-input-skeleton' />
          </div>
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default LoginSkeleton;
