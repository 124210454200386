import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import 'typeface-inter';
import 'typeface-roboto';
import { AuthGuard } from './utils/helpers/AuthGuard';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import StoreProvider from './store/store';
import WidgetSkeleton from './components/Skeleton/Widget';
import LoginSkeleton from './components/Skeleton/Login';

import { configureAmplify } from "./amplify-config";


const LoginContainer = React.lazy(() => import('./containers/LoginContainer'));
const RootHandler = React.lazy(() => import('./containers/RootHandler'));

const App = () => {
  const [amplifyConfigured, setAmplifyConfigured] = useState(false);

  useEffect(() => {
    configureAmplify();
    setAmplifyConfigured(true);
  }, []);

  if (!amplifyConfigured) {
    return <div>Loading...</div>;
  }

  return (
    <StoreProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<React.Suspense fallback={<LoginSkeleton />}><LoginContainer /></React.Suspense>} />
          <Route path='/' element={<AuthGuard><React.Suspense fallback={<WidgetSkeleton />}><RootHandler /></React.Suspense></AuthGuard>} />
        </Routes>
      </Router>
    </StoreProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

