import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthContext from './AuthContext';
import { getCurrentUser } from '@aws-amplify/auth'

export function AuthGuard({ children }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orgId = searchParams.get('orgId');
  const appId = searchParams.get('appId');
  const navigate = useNavigate();
  const [shouldRenderChildren, setShouldRenderChildren] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const isLoggedIn = async () => {
    try {
      const currentUser = await getCurrentUser()
      if (currentUser.username === orgId){
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error)
      return false;
    }
  };

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await isLoggedIn();
      setIsAuthenticated(loggedIn);  
      setShouldRenderChildren(loggedIn);
      if (!loggedIn) {
        const params = `${orgId ? '?orgId=' + orgId : ''}${appId ? '&appId=' + appId : ''}`;
        navigate(`/login${params}`);
        return;
      }
    };
    if (appId === 'employee' && orgId !== 'betterburritos'){
      checkLoginStatus();
    } else {
      setIsAuthenticated(true);
      setShouldRenderChildren(true);
    }
  }, [navigate, appId, orgId]);

  return <AuthContext.Provider value={isAuthenticated}>{shouldRenderChildren && children}</AuthContext.Provider>;
}
