import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';


export function configureAmplify() {
  console.log('configuring amplify')
  console.log('process.env.REACT_APP_COGNITO_USER_POOL_ID', process.env.REACT_APP_COGNITO_USER_POOL_ID)
  console.log('process.env.REACT_APP_COGNITO_CLIENT_ID', process.env.REACT_APP_COGNITO_CLIENT_ID)
  const amplifyConfig = {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      }
    }
  }
  Amplify.configure(amplifyConfig)
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({domain: '.vistry-api.com'}));
  cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

}
