import React from 'react';
import './WidgetSkeleton.css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function WidgetSkeleton() {
  return (
    <SkeletonTheme baseColor='#202020' highlightColor='#444'>
      <div className='main'>
        <div className='header'>
          <div className='left-col'>
            <div className='widget-avatar'>
              <Skeleton circle height='100%' containerClassName='avatar-skeleton' />
            </div>
          </div>
          <div className='right-col'>
            <p className='widget-subtitle'>
              <Skeleton />
            </p>
            <h2 className='widget-user-name'>
              <Skeleton />
            </h2>
          </div>
        </div>
        <div className='widget-body'>
          <Skeleton count={8} />
        </div>
        <div className='widget-body second-body'>
          <Skeleton count={8} />
        </div>
        <div className='footer'>
          <Skeleton borderRadius={24} height={40} />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default WidgetSkeleton;
