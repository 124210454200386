// store/app/appActions.js
import appDataSlice from './appSlice';
const { actions } = appDataSlice;

export const setMessages = (data) => async (dispatch) => {
  dispatch(actions.setMessages(data));
};
export const setPanel = (data) => async (dispatch) => {
  dispatch(actions.setPanel(data));
};
export const setPanelType = (data) => async (dispatch) => {
  dispatch(actions.setPanelType(data));
};
export const setLocation = (data) => async (dispatch) => {
  dispatch(actions.setLocation(data));
};
export const setTheme = (data) => async (dispatch) => {
  dispatch(actions.setTheme(data));
};
export const removeMessageById = (data) => async (dispatch) => {
  dispatch(actions.removeMessageById(data));
};

export const removePanel = (data) => async (dispatch) => {
  dispatch(actions.removePanel(data));
};
export const setToast = (data) => async (dispatch) => {
  dispatch(actions.setToast(data));
};
export const setPayment = (data) => async (dispatch) => {
  dispatch(actions.setPayment(data));
};
export const setOrderItemCount = (data) => async (dispatch) => {
  dispatch(actions.setOrderItemCount(data));
};
export const setPanelSwitch = (data) => async (dispatch) => {
  dispatch(actions.setPanelSwitch(data));
};
export const setSuggestions = (data) => async (dispatch) => {
  dispatch(actions.setSuggestions(data));
};
export const logout = () => async (dispatch) => {
  dispatch(actions.logout());
};
export const setOrgSettings = (data) => async (dispatch) => {
  dispatch(actions.setOrgSettings(data));
}
export const setWebSocket = (data) => async (dispatch) => {
  dispatch(actions.setWebSocket(data));
};
