// store/rootReducer.js
import { combineReducers } from 'redux';
import persistReducer from 'redux-persist/es/persistReducer';
import localStorage from 'redux-persist/es/storage';
import appDataSlice from './app/appSlice';

const appDataPersistConfig = {
  key: 'appData',
  storage: localStorage,
};

const rootReducer = combineReducers({
  mainAppData: persistReducer(appDataPersistConfig, appDataSlice.reducer),
});
export default rootReducer;
