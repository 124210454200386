// store/app/appSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const initialApplicationState = {
  messages: [],
  panels: [],
  panelType: '',
  location: '',
  toast: {},
  paymentAdded: '',
  orderItemCount: '',
  suggestions: [],
  webSocket: null
};

const appDataSlice = createSlice({
  name: 'appData',
  initialState: initialApplicationState,
  reducers: {
    setMessages: (state, action) => {
      const newMessage = action.payload;
      const existingMessageIndex = state.messages.findIndex((message) => message._id === newMessage._id);

      if (existingMessageIndex !== -1) {
        state.messages[existingMessageIndex].message += newMessage.message;
      } else {
        state.messages.push(newMessage);
      }
    },
    setPanel: (state, action) => {
      const newPanels = action.payload;
      console.log('newPanels', newPanels)
      const existingPanelIndex = state.panels.findIndex((panelArray) => panelArray[0] && panelArray[0]._id === newPanels._id);
      console.log('existingPanelIndex', existingPanelIndex)
      if (existingPanelIndex !== -1) {
        state.panels[existingPanelIndex].push(newPanels);
      } else {
        state.panels.push([newPanels]);
      }
    },
    setPanelSwitch: (state, action) => {
      const newPanels = action.payload;
      state.panels.push(newPanels);
    },
    setPanelType: (state, action) => {
      if (action.payload === 'suggestions') {}
      else if (
        action.payload === 'menu' ||
        action.payload === 'location' ||
        action.payload === 'source' ||
        action.payload === 'contact'
      ) {
        let largestOrderIndex = -1;
        for (let i = 0; i < state.panels.length; i++) {
          if (state.panels[i].length > 0 && state.panels[i][0].panel[0].type === action.payload && i > largestOrderIndex) {
            largestOrderIndex = i;
          }
        }
        if (largestOrderIndex !== -1) {
          const largestOrderObject = state.panels.splice(largestOrderIndex, 1)[0];
          state.panels.push(largestOrderObject);
        }
      } else {
        let largestOrderIndex = -1;
        for (let i = 0; i < state.panels.length; i++) {
          const panel = state.panels[i][0].panel[0];
          if (panel.type === action.payload && i > largestOrderIndex) {
            if (panel.type === 'order' && panel?.attributes?.items?.length === 0) {
            } else {
              largestOrderIndex = i;
            }
          }
        }
        if (largestOrderIndex !== -1) {
          const largestOrderObject = state.panels.splice(largestOrderIndex, 1)[0][0];
          state.panels.push([largestOrderObject]);
        } else {
          if (action.payload === 'order') {
            state.panels.push([
              {
                _id: nanoid(),
                panel: [{ type: 'order', showEmptyCart: true }],
              },
            ]);
          }
        }
      }

      state.panelType = action.payload;
    },
    setTheme: (state, action) => {
      const newTheme = action.payload;
      state.themes = newTheme;
    },
    setOrgSettings: (state, action) => {
      const newOrgSettings = action.payload;
      state.orgSettings = newOrgSettings;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setPayment: (state, action) => {
      state.paymentAdded = action.payload;
    },
    setOrderItemCount: (state, action) => {
      state.orderItemCount = action.payload;
    },
    removeMessageById: (state, action) => {
      const messageId = action.payload;
      const existingMessageIndex = state.messages.findIndex((message) => message._id === messageId);

      if (existingMessageIndex !== -1) {
        state.messages.splice(existingMessageIndex, 1);
      }
    },
    removePanel: (state, action) => {
      state.panels = state.panels.filter((panelGroup) => {
        const panel = panelGroup[0].panel[0];
        return panel.type !== 'order';
      });
    },
    setToast: (state, action) => {
      state.toast = action.payload;
    },
    setSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    logout: (state, action) => {
      Object.keys(initialApplicationState).forEach((key) => {
        state[key] = initialApplicationState[key];
      });
    },
    setWebSocket: (state, action) => {
      state.webSocket = action.payload;
    },
  },
});

export default appDataSlice;
